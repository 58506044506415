import styled from "styled-components";

import printCover from "../../images/Dragon-e-Baby-Gone.jpg";
import audibleCover from "../../images/Dragon-e-Baby-Gone-Audible.jpg";
import kindleRow from "../../images/kindleRow.png";
import audible from "../../images/audible.png";
import nook from "../../images/nook.png";

const DragoneBabyGone = () => {
  return (
    <Page>
      <TopContainer>
        <ImageDiv>
          <Image
            src={audibleCover}
            alt='"Dragon(e) Baby Gone" by Robert Gainey audible cover'
          />
          <Image
            src={printCover}
            alt='"Dragon(e) Baby Gone" by Robert Gainey'
          />
        </ImageDiv>

        <TextBox>
          <ParaNI>“Dragon is hard to overcome, yet one shall try.”</ParaNI>
          <ParaNI>– Nowe Ateny, Polish Encyclopedia, 1745</ParaNI>
          <Para>
            Diane Morris is part of the thin line separating a happy, mundane
            world from all of the horrors of the anomalous. Her federal agency
            is underfunded, understaffed, and misunderstood, and she’d rather
            transfer to the boring safety of Logistics than remain a field
            agent. When a troupe of international thieves make off with a pair
            of dragon eggs, Diane has no choice but to ally with a demon against
            the forces looking to leave her city a smoldering crater. Facing
            down rogue wizards, fiery elementals, and crazed gunmen, it’s a race
            against time to get the precious cargo back before the dragon wakes
            up and unleashes hell.{" "}
          </Para>
          <PreOrder>
            <PreOrderText>Available now!</PreOrderText>
            <OrderBox>
              <a
                href="https://www.amazon.com/dp/B095GNZJCN"
                target="_blank"
                rel="noreferrer"
              >
                <OrderNow src={kindleRow} alt="Available on Kindle" />
              </a>
              <a
                href="https://www.barnesandnoble.com/w/dragon-robert-gainey/1139526445"
                target="_blank"
                rel="noreferrer"
              >
                <OrderNow src={nook} alt="Available on Nook" />
              </a>
              <a
                href="https://www.audible.com/pd/Dragone-Baby-Gone-Audiobook/B09SBRVND7"
                target="_blank"
                rel="noreferrer"
              >
                <OrderNow src={audible} alt="Available on Kindle" />
              </a>
            </OrderBox>
          </PreOrder>
        </TextBox>
      </TopContainer>
      <BottomContainer>
        <ReviewHeadline>
          All Of Your Awesome Reviews Will Go Here
        </ReviewHeadline>
      </BottomContainer>
    </Page>
  );
};

const Page = styled.div`
  width: 70%;
  margin: 1rem auto 0;
  padding: 1rem 0;

  @media screen and (max-width: 1640px) {
    width: 80%;
  }

  @media screen and (max-width: 699px) {
    margin-top: 2rem;
  }
`;
const TopContainer = styled.section`
  display: flex;
  justify-content: space-evenly;

  @media screen and (max-width: 1355px) {
    flex-wrap: wrap;
  }
`;
const ImageDiv = styled.div`
  margin: 1rem auto;
  width: 38%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 1355px) {
    width: 70%;
  }
`;
const Image = styled.img`
  width: 60%;
  border: 5px solid #e3eff3;
  margin-bottom: 1rem;
`;
const TextBox = styled.div`
  margin: 1rem 0;
  padding: 2rem 0;
  width: 50%;
  font-size: 1.4rem;
  text-align: justify;

  @media screen and (max-width: 1355px) {
    width: 70%;
    margin: 0;
  }

  @media screen and (max-width: 699px) {
    font-size: 1.2rem;
  }

  @media screen and (max-width: 475px) {
    font-size: 1rem;
  }
`;
const Para = styled.p`
  text-indent: 2.5rem;
  padding: 1rem 1rem 0.75rem;
  line-height: 125%;
`;
const ParaNI = styled(Para)`
  text-indent: 0;
  padding: 1.5rem 1rem 0.75rem;
  font-style: italic;
`;
const PreOrder = styled(Para)`
  margin: 1rem 0;
  text-indent: 0;
  text-align: right;
`;
const PreOrderText = styled.h3`
  @media screen and (max-width: 699px) {
    font-size: 1.2rem;
  }

  @media screen and (max-width: 475px) {
    font-size: 1rem;
  }
`;
const OrderBox = styled.div``;
const OrderNow = styled.img`
  height: 6vh;
  margin: 0.5rem 1rem;

  @media screen and (max-width: 475px) {
    height: 4vh;
  }

  @media screen and (max-width: 342px) {
    height: 3vh;
  }
`;
const BottomContainer = styled.section`
  margin-top: 2rem;
  display: none;
`;
const ReviewHeadline = styled.h2`
  font-size: 4rem;
`;

export default DragoneBabyGone;
