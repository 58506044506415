import { Switch, Route } from "react-router-dom";
import styled from "styled-components";

import Header from "./components/Static/Header";
import Footer from "./components/Static/Footer";

import DragoneBaby from "./components/Books/DragoneBabyGone";
import WitchesGetStitches from "./components/Books/WitchesGetStitches";

import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import Events from "./components/Events/Events";
import Subscribe from "./components/Subscribe/Subscribe";
import Raffle from "./components/Subscribe/Raffle";

const Router = styled.div`
  position: relative;
  min-height: 100vh;
`;

const App = () => {
  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path="/">
          <WitchesGetStitches />
        </Route>

        <Route exact path="/witches-get-stitches">
          <WitchesGetStitches />
        </Route>

        <Route path="/dragon-e-baby-gone">
          <DragoneBaby />
        </Route>

        <Route path="/about">
          <About />
        </Route>

        <Route path="/contact">
          <Contact />
        </Route>

        <Route path="/events">
          <Events />
        </Route>

        <Route path="/subscribe">
          <Subscribe />
        </Route>
        <Route path="/raffle">
          <Raffle />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
};

export default App;
