import styled from "styled-components";

import fb from "../../images/fb.png";
import ig from "../../images/ig.png";
import twitter from "../../images/twitter.png";
import goodreads from "../../images/goodreads.png";

const Page = styled.div`
  width: 70%;
  margin: 2rem auto 0;
  padding: 1rem 0 2.25rem;
  display: flex;
  justify-content: space-evenly;

  @media screen and (max-width: 1300px) {
    flex-wrap: wrap-reverse;
  }

  @media screen and (max-width: 699px) {
    margin-top: 2.5rem;
  }
`;
const Links = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1300px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
`;
const PerLink = styled.div`
  display: flex;
  flex-direction: row;
`;
const ATag = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.5rem;

  color: #cde3eb !important;
  background-color: transparent !important;
  text-decoration: none !important;

  &:hover {
    color: #6e828a !important;
    text-decoration: underline !important;
  }

  &:active {
    color: #6e828a !important;
    text-decoration: underline !important;
    font-weight: bold !important;
  }

  @media screen and (max-width: 800px) {
    font-size: 1.5rem;
  }
`;
const Icon = styled.img`
  height: 4rem;
  margin: 1rem 1rem 1rem 0;

  @media screen and (max-width: 380px) {
    height: 2rem;
  }
`;
const LinkName = styled.p``;
const Submit = styled.form`
  display: flex;
  flex-direction: column;
`;
const TopRow = styled.div``;
const Input = styled.input`
  font-size: 1.25rem;
  padding: 0.25rem;
  margin: 0.5rem;

  @media screen and (max-width: 1300px) {
    width: 100%;
  }
`;
const Message = styled.textarea`
  padding: 0.25rem;
  margin: 0.5rem;
  font-size: 1.25rem;

  @media screen and (max-width: 1300px) {
    width: 100%;
  }
`;
const Button = styled.button`
  font-size: 1.25rem;
  padding: 0.25rem;
  margin: 0.5rem;

  @media screen and (max-width: 1300px) {
    width: 100%;
  }
`;
const Hidden = styled.input`
  display: none;
`;

const Contact = () => {
  return (
    <Page>
      <Links>
        {/* <PerLink>
                    <ATag href="https://robertgainey.blogspot.com/" target="_blank" rel="noreferrer">
                        <Icon src={blogger} alt ={blogger} />
                        <LinkName>robertgainey.blogspot.com</LinkName>
                    </ATag>
                </PerLink> */}
        <PerLink>
          <ATag
            href="https://www.facebook.com/TheIntangibleAsset"
            target="_blank"
            rel="noreferrer"
          >
            <Icon src={fb} alt="Facebook icon, The Intangible Asset" />
            <LinkName>facebook.com/TheIntangibleAsset</LinkName>
          </ATag>
        </PerLink>
        <PerLink>
          <ATag
            href="https://www.instagram.com/robertngainey/"
            target="_blank"
            rel="noreferrer"
          >
            <Icon src={ig} alt="Instagram icon, Robert Gainey" />
            <LinkName>instagram.com/robertngainey/</LinkName>
          </ATag>
        </PerLink>
        <PerLink>
          <ATag
            href="https://twitter.com/RNGainey"
            target="_blank"
            rel="noreferrer"
          >
            <Icon src={twitter} alt="Twitter icon, Robert Gainey" />
            <LinkName>twitter.com/RNGainey</LinkName>
          </ATag>
        </PerLink>
        <PerLink>
          <ATag
            href="https://www.goodreads.com/robertgainey"
            target="_blank"
            rel="noreferrer"
          >
            <Icon src={goodreads} alt="Goodreads icon, Robert Gainey, author" />
            <LinkName>goodreads.com/robertgainey</LinkName>
          </ATag>
        </PerLink>
      </Links>

      <Submit
        target="_blank"
        action="https://formsubmit.co/190bff0247d0c0ff895afb4405a508f6"
        method="POST"
      >
        <TopRow>
          <Input type="text" name="name" placeholder="First Name" required />
          <Input
            type="email"
            name="email"
            placeholder="email address"
            required
          />
        </TopRow>
        <Hidden type="text" name="_honey"></Hidden>
        <Hidden type="hidden" name="_captcha" value="false"></Hidden>
        <Message
          name="message"
          placeholder="Your message here"
          rows="10"
          required
        />
        <Button type="submit">Submit Form</Button>
      </Submit>
    </Page>
  );
};

export default Contact;
