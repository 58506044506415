import styled from "styled-components";

import witchesCover from "../../images/Witches Get Stitches.jpg";
import kindleRow from "../../images/kindleRow.png";

const WitchesGetStitches = () => {
  return (
    <Page>
      <TopContainer>
        <ImageDiv>
          <Image
            src={witchesCover}
            alt='"Witches Get Stitches" by Robert Gainey audible cover'
          />
        </ImageDiv>

        <TextBox>
          <Para>
            Diane Morris is thirsty for a break from chasing down supernatural
            threats. Life as a Special Agent in the Department of Intangible
            Assets is a perilous one, so Diane is more than happy to spend
            quality time with her girlfriend and avoid the kinds of
            life-threatening situations her career throws at her. It’s bad
            enough her partner/roommate is a bored, terrifying demon, but so
            long as she gets to stay behind her desk and shuffle paperwork,
            Diane’s content to live a quiet life.
          </Para>
          <Para>
            When two of her fellow DIA agents go missing in small town Wickston,
            Diane begrudgingly finds herself thrown into a smoldering conflict
            between a witches’ coven and a fledgling cult. With both sides
            holding the other responsible for a rash of missing persons, Diane
            and her otherworldly partner Archades find themselves drawn deeper
            into a conspiracy spanning decades. Creatures lurk in the dark
            shadows of moonlit orchards. Sinister puppeteers hide in plain sight
            on Main Street. Cut off with no hope of relief, Diane has to make a
            choice between playing things safe or uncovering the truth of the
            idyllic Georgia town’s murky history. One may risk her life, but the
            other risks so much more.
          </Para>
          <PreOrder>
            <PreOrderText>Available now!</PreOrderText>
            <OrderBox>
              <a
                href="https://www.amazon.com/dp/B0B36PFCZH"
                target="_blank"
                rel="noreferrer"
              >
                <OrderNow src={kindleRow} alt="Available on Kindle" />
              </a>
            </OrderBox>
          </PreOrder>
        </TextBox>
      </TopContainer>
      <BottomContainer>
        <ReviewHeadline>
          All Of Your Awesome Reviews Will Go Here
        </ReviewHeadline>
      </BottomContainer>
    </Page>
  );
};

const Page = styled.div`
  width: 70%;
  margin: 1rem auto 0;
  padding: 1rem 0;

  @media screen and (max-width: 1640px) {
    width: 80%;
  }

  @media screen and (max-width: 699px) {
    margin-top: 2rem;
  }
`;
const TopContainer = styled.section`
  display: flex;
  justify-content: space-evenly;

  @media screen and (max-width: 1355px) {
    flex-wrap: wrap;
  }
`;
const ImageDiv = styled.div`
  margin: 1rem auto;
  width: 38%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 1355px) {
    width: 70%;
  }
`;
const Image = styled.img`
  width: 100%;
  border: 5px solid #e3eff3;
  margin-bottom: 1rem;
`;
const TextBox = styled.div`
  margin: 1rem 0;
  padding: 2rem 0;
  width: 50%;
  font-size: 1.4rem;
  text-align: justify;

  @media screen and (max-width: 1355px) {
    width: 70%;
    margin: 0;
  }

  @media screen and (max-width: 699px) {
    font-size: 1.2rem;
  }

  @media screen and (max-width: 475px) {
    font-size: 1rem;
  }
`;
const Para = styled.p`
  text-indent: 2.5rem;
  padding: 1rem 1rem 0.75rem;
  line-height: 125%;
`;
const PreOrder = styled(Para)`
  margin: 1rem 0;
  text-indent: 0;
  text-align: right;
`;
const PreOrderText = styled.h3`
  @media screen and (max-width: 699px) {
    font-size: 1.2rem;
  }

  @media screen and (max-width: 475px) {
    font-size: 1rem;
  }
`;
const OrderBox = styled.div``;
const OrderNow = styled.img`
  height: 6vh;
  margin: 0.5rem 1rem;

  @media screen and (max-width: 475px) {
    height: 4vh;
  }

  @media screen and (max-width: 342px) {
    height: 3vh;
  }
`;
const BottomContainer = styled.section`
  margin-top: 2rem;
  display: none;
`;
const ReviewHeadline = styled.h2`
  font-size: 4rem;
`;

export default WitchesGetStitches;
