import styled from "styled-components";

const Subscribe = () => {
  return (
    <Page>
      <div>
        <TextBox>
          <Para>
            Subscribe to my newletter to be the first to find out when I have a
            new book out or coming to an event near you.
          </Para>
        </TextBox>
        <div class="ml-embedded" data-form="8fCo2V" />
      </div>
    </Page>
  );
};

const MLEmbed = styled.div`
  min-height: 100px;
`;

const Page = styled.div`
  width: 70%;
  margin: 1rem auto 0;
  padding: 3rem 0;
  display: flex;
  justify-content: space-evenly;
  @media screen and (max-width: 1640px) {
    width: 80%;
  }

  @media screen and (max-width: 699px) {
    flex-wrap: wrap;
    margin-top: 2rem;
    padding: 0;
  }
`;
const TextBox = styled.div`
  font-size: 1.4rem;
  text-align: justify;

  @media screen and (max-width: 1355px) {
    margin: 2rem 0;
  }

  @media screen and (max-width: 699px) {
    font-size: 1.2rem;
    width: 100%;
  }

  @media screen and (max-width: 475px) {
    font-size: 1rem;
    margin: 1rem 0;
  }
`;
const Para = styled.p`
  padding: 1rem 0 0.75rem;
  line-height: 125%;
`;

export default Subscribe;
