import { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import {
  Collapse,
  DropdownToggle,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import fb from "../../images/fb.png";
import ig from "../../images/ig.png";
import twitter from "../../images/twitter.png";
import goodreads from "../../images/goodreads.png";

const Page = styled.header`
  height: 25vh;
  padding-top: 1rem;

  @media screen and (max-width: 800px) {
    height: 20vh;
  }

  @media screen and (max-height: 700px) {
    height: 27vh;
  }

  @media screen and (max-height: 612px) {
    height: 30vh;
    padding-top: 1.5rem;
  }

  @media screen and (max-height: 546px) {
    height: 32vh;
  }
`;
const TitleBox = styled.div`
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 800px) {
    height: 10vh;
  }
`;
const Links = styled.div`
  display: flex;
  width: 80%;
  margin: auto;
  justify-content: space-evenly;
  align-items: center;

  @media screen and (max-height: 700px) {
    padding-top: 1rem;
  }
`;
const IconDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const ATag = styled.a`
  color: #cde3eb !important;
  background-color: transparent !important;
  text-decoration: none !important;

  &:hover {
    color: #6e828a !important;
    text-decoration: underline !important;
  }

  &:active {
    color: #6e828a !important;
    text-decoration: underline !important;
    font-weight: bold !important;
  }
`;
const Icon = styled.img`
  height: 2rem;

  @media screen and (max-width: 800px) {
    height: 1.5rem;
  }
`;
const Name = styled.h1`
  font-size: 7.5vw;

  @media screen and (max-width: 699px) {
    font-size: 3.25rem;
  }
`;
const NavMainBar = styled.nav`
  width: 70%;
  margin: 1.5rem auto 0;
  display: flex;
  flex-direction: row;
  font-size: 2rem;
  justify-content: space-around;
  align-items: center;

  @media screen and (max-width: 1040px) {
    display: none;
  }
`;
const NavName = styled.a`
  cursor: pointer;
  color: #cde3eb !important;
  background-color: transparent !important;
  text-decoration: none !important;
  font-family: GARA !important;

  &:hover {
    color: #6e828a !important;
    background-color: transparent !important;
    text-decoration: underline !important;

    &:active {
      font-weight: bold !important;
    }
  }

  &:visited {
    color: #cde3eb !important;
    background-color: transparent !important;
    text-decoration: none !important;
  }

  @media screen and (max-width: 699px) {
    font-size: 1.5rem;
  }
`;

const NavDrop = styled(Navbar)`
  display: none;

  @media screen and (max-width: 1040px) {
    visibility: visible;
    display: block;
  }
`;
const NavCollapse = styled(Collapse)`
  border: 2px solid white;
  background-color: #143a52;
  padding: 0.5rem 1rem;
`;

const Hamburger = styled(NavbarToggler)``;

const DropdownObjectHamburger = styled(DropdownMenu)`
  background-color: #143a52;
`;
const DropdownObject = styled(DropdownObjectHamburger)`
  border: 2px solid white;
`;
const DropdownSelect = styled(DropdownItem)`
  color: white;
  font-size: 1.25rem;
`;
const DropdownTogg = styled(DropdownToggle)`
  font-size: 2rem;

  color: #cde3eb !important;
  background-color: transparent !important;
  text-decoration: none !important;

  &:hover {
    color: #6e828a !important;
    text-decoration: underline !important;
  }

  &:active {
    color: #6e828a !important;
    text-decoration: underline !important;
    font-weight: bold !important;
  }

  @media screen and (max-width: 800px) {
    font-size: 1.5rem;
  }
`;

const Header = () => {
  const { push } = useHistory();

  const goHome = () => push(`/`);

  const dragonebaby = () => push("/dragon-e-baby-gone");
  const witches = () => push("/witches-get-stitches");

  const about = () => push("/about");
  const contact = () => push("/contact");
  const events = () => push("/events");
  const subscribe = () => push("subscribe");

  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <Page>
      <TitleBox>
        <Name onClick={goHome}>Robert Gainey</Name>
      </TitleBox>
      <Links>
        <NavDrop color="faded" dark>
          <Hamburger onClick={toggleNavbar} className="mr-2" />
          <NavCollapse isOpen={!collapsed} navbar>
            <Nav navbar>
              <UncontrolledDropdown nav inNavbar>
                <DropdownTogg nav caret>
                  Books
                </DropdownTogg>

                <DropdownObjectHamburger left>
                  <DropdownSelect onClick={dragonebaby}>
                    Dragon(e) Baby Gone
                  </DropdownSelect>
                  <DropdownSelect onClick={witches}>
                    Witches Get Stitches
                  </DropdownSelect>
                </DropdownObjectHamburger>
              </UncontrolledDropdown>
              <NavItem>
                <NavName onClick={about}>About the Author</NavName>
              </NavItem>
              <NavItem>
                <NavName onClick={contact}>Contact</NavName>
              </NavItem>
              <NavItem>
                <NavName onClick={events}>Events</NavName>
              </NavItem>
              <NavItem>
                <NavName onClick={subscribe}>Subscribe</NavName>
              </NavItem>
              {/* 
                            <NavItem>
                                <NavName onClick = {events}>Events</NavName> 
                            </NavItem>
                            */}
            </Nav>
          </NavCollapse>
        </NavDrop>
        {/* <IconDiv>
          <ATag
            href="https://robertgainey.blogspot.com/"
            target="_blank"
            rel="noreferrer"
          >
            <Icon src={blogger} alt={blogger} />
          </ATag>
        </IconDiv> */}
        <IconDiv>
          <ATag
            href="https://www.facebook.com/TheIntangibleAsset"
            target="_blank"
            rel="noreferrer"
          >
            <Icon src={fb} alt="Facebook icon, The Intangible Asset" />
          </ATag>
        </IconDiv>
        <IconDiv>
          <ATag
            href="https://www.instagram.com/robertngainey/"
            target="_blank"
            rel="noreferrer"
          >
            <Icon src={ig} alt="Instagram icon, Robert Gainey" />
          </ATag>
        </IconDiv>
        <IconDiv>
          <ATag
            href="https://twitter.com/RNGainey"
            target="_blank"
            rel="noreferrer"
          >
            <Icon src={twitter} alt="Twitter icon, Robert Gainey" />
          </ATag>
        </IconDiv>
        <IconDiv>
          <ATag
            href="https://www.goodreads.com/robertgainey"
            target="_blank"
            rel="noreferrer"
          >
            <Icon src={goodreads} alt="Goodreads icon, Robert Gainey, author" />
          </ATag>
        </IconDiv>
      </Links>
      <NavMainBar>
        {/* <NavName onClick={goHome}>Books</NavName> */}
        <UncontrolledDropdown nav inNavbar>
          <DropdownTogg nav caret>
            Books
          </DropdownTogg>

          <DropdownObject left>
            <DropdownSelect onClick={dragonebaby}>
              Dragon(e) Baby Gone
            </DropdownSelect>
            <DropdownSelect onClick={witches}>
              Witches Get Stitches
            </DropdownSelect>
          </DropdownObject>
        </UncontrolledDropdown>
        <NavName onClick={about}>About the Author</NavName>
        <NavName onClick={contact}>Contact</NavName>
        <NavName onClick={events}>Events</NavName>
        <NavName onClick={subscribe}>Subscribe</NavName>
      </NavMainBar>
    </Page>
  );
};

export default Header;
