import styled from "styled-components";
import dragonEggs from "../../images/dragon-eggs.jpg";

const Raffle = () => {
  const endOfRaffle = new Date("October 30, 2022");
  const signUpForRaffle = new Date() < endOfRaffle;
  return (
    <Page>
      <ImgDiv>
        <Image src={dragonEggs} alt="Dragon eggs created by Lisa Parker" />
      </ImgDiv>
      <div>
        <TextBox>
          {signUpForRaffle ? (
            <div>
              <Para>
                Thanks for coming out to Spooky Empire! Subscribe to the
                newsletter here for a chance to win one of these eggs designed
                by{" "}
                <ATag
                  href="https://www.instagram.com/m0ntressa/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Lisa Parke
                </ATag>
                !
              </Para>
            </div>
          ) : (
            <Para>
              The raffle is over! Sign up to find out when the next raffle is
              happening and be the first to learn about new books coming out!
            </Para>
          )}
        </TextBox>
        <div class="ml-embedded" data-form="W8oeON" />
      </div>
    </Page>
  );
};

const Page = styled.div`
  width: 70%;
  margin: 1rem auto 0;
  padding: 3rem 0;
  display: flex;
  justify-content: space-evenly;
  @media screen and (max-width: 1640px) {
    width: 80%;
  }

  @media screen and (max-width: 699px) {
    flex-wrap: wrap;
    margin-top: 2rem;
  }
`;

const ATag = styled.a`
  color: #cde3eb !important;
  background-color: transparent !important;

  &:hover {
    color: #6e828a !important;
  }

  &:active {
    color: #6e828a !important;
    font-weight: bold !important;
  }
`;

const ImgDiv = styled.div`
  margin-right: 4rem;

  @media screen and (max-width: 699px) {
    margin: 0;
  }
`;

const Image = styled.img`
  max-height: 50vh;
  border: 5px solid #e3eff3;
`;

const TextBox = styled.div`
  min-width: 250px;
  font-size: 1.4rem;
  text-align: justify;

  @media screen and (max-width: 1355px) {
    width: 100%;
    margin: 2rem 0;
  }

  @media screen and (max-width: 699px) {
    font-size: 1.2rem;
    width: 100%;
  }

  @media screen and (max-width: 475px) {
    font-size: 1rem;
    margin: 1rem 0;
  }
`;

const Para = styled.p`
  padding: 1rem 0 0.75rem;
  line-height: 125%;
`;

export default Raffle;
