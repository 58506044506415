import styled from "styled-components";
import robert from "../../images/robert-gainey.jpg";
// import betterRobert from '../../images/IMG_2708.jfif'

const Page = styled.div`
  width: 70%;
  margin: 1rem auto;
  padding: 1rem 0;
  display: flex;
  justify-content: space-evenly;

  @media screen and (max-width: 1640px) {
    width: 80%;
  }

  @media screen and (max-width: 1355px) {
    flex-wrap: wrap;
    margin: 0 auto;
  }
`;
const TextBox = styled.div`
  margin: 5rem 0;
  width: 50%;
  font-size: 1.4rem;
  text-align: justify;

  @media screen and (max-width: 1355px) {
    width: 70%;
    margin: 2rem 0;
  }

  @media screen and (max-width: 1000px) {
    width: 75%;
  }

  @media screen and (max-width: 699px) {
    font-size: 1.2rem;
    width: 80%;
  }

  @media screen and (max-width: 475px) {
    font-size: 1rem;
    margin: 1rem 0;
  }
`;
const Para = styled.p`
  text-indent: 2.5rem;
  padding: 1rem 0 0.75rem;
  line-height: 125%;
`;
const ImageDiv = styled.div`
  margin: 1rem 0;
  width: 28%;

  @media screen and (max-width: 1355px) {
    width: 50%;
    padding-bottom: 2rem;
  }
`;
const Image = styled.img`
  width: 100%;
  border: 5px solid #e3eff3;
`;

const About = () => {
  return (
    <Page>
      <TextBox>
        <Para>
          Robert Gainey is a born and raised Floridian, despite his best
          efforts. While enrolled at Florida State University and studying
          English (a language spoken on a small island near Europe), Robert
          began volunteering for the campus medical response team, opening up a
          great new passion in his life. Following graduation, he pursued
          further training through paramedic and firefighting programs, going on
          to become a full time professional firefighter in the State of
          Florida. He currently lives and works in Northeast Florida with his
          wife and dogs, who make sure he gets walked regularly. Robert writes
          near-fetched fantasy novels inspired by the madness and courage found
          in everyday events.
        </Para>
      </TextBox>

      <ImageDiv>
        <Image src={robert} alt="Author Robert Gainey" />
        {/* <Image src={betterRobert} alt="Author Robert Gainey"/> */}
      </ImageDiv>
    </Page>
  );
};

export default About;
