import styled from "styled-components";

import spooky from "../../images/spooky.png";

const Events = () => {
  return (
    <Page>
      <EventDiv>
        <EventDetailsDiv>
          <EventImg src={spooky} alt="Spooky Empire 2022" />
        </EventDetailsDiv>
        <EventDetailsDiv>
          <EventName>Spooky Empire</EventName>
          <EventDetails>Orange County Convention Center</EventDetails>
          <EventDetails>Orlando, FL</EventDetails>
          <EventDetails>October 21-23, 2022</EventDetails>
        </EventDetailsDiv>
      </EventDiv>
    </Page>
  );
};

export default Events;

const Page = styled.div`
  display: flex;
  justify-content: center;
  padding: 3rem 0;
`;

const EventDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const EventImg = styled.img``;
const EventDetailsDiv = styled.div`
  margin: 0 1.5rem;
`;

const EventName = styled.h4`
  font-size: 2rem;
`;

const EventDetails = styled.p`
  font-size: 1.5rem;
  margin: 0;
`;
