import styled from "styled-components";

const Page = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-top: 2rem;
  background-color: #6e828a;
  color: #143a52;
`;
const Copyright = styled.h2`
  font-size: 0.75rem;
  text-align: center;
  padding: 0.75rem;
`;

const Footer = () => {
  return (
    <Page>
      <Copyright>© Robert Gainey 2021</Copyright>
    </Page>
  );
};

export default Footer;
